export default [
  { title: "Received", value: "RECEIVED", bg_color: "#777777" },
  { title: "Processing", value: "PROCESSING", bg_color: "#9966FF" },
  { title: "Booked", value: "BOOKED", bg_color: "#2F75B5" },
  { title: "Partial Booked", value: "PARTIAL_BOOKED", bg_color: "#9BC2E6" },
  {
    title: "Partial In Transit",
    value: "PARTIAL_IN_TRANSIT",
    bg_color: "#A9D08E",
  },
  { title: "In Transit", value: "IN_TRANSIT", bg_color: "#548235" },
  {
    title: "Cancellation Requested",
    value: "CANCELLATION_REQUESTED",
    bg_color: "#FF9999",
  },
  {
    title: "Waiting Qty Confirmation",
    value: "WAITING_QTY_CONFIRMATION",
    bg_color: "#FFD966",
  },
  { title: "Cancelled", value: "CANCELLED", bg_color: "#FF0000" },
  { title: "Closed", value: "CLOSED", bg_color: "#FF0000" },
];
