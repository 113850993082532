import {createStore} from 'vuex'
import {snackbar} from './snackbar.module'
import {panels} from './panels.module'
import {edit} from './edit.module'
import {account} from './account.module'
import {activeFilters} from './activeFilters.module'


let state = {}

const store = createStore({
    plugins: [],
    state() {
        return state
    },
    mutations: {
        loadStore() {
            if (localStorage.getItem('store')) {
                try {
                    let tmpState = JSON.parse(localStorage.getItem('store'))
                    for (let key in tmpState) {
                        if (key !== 'panels' && key !== 'snackbar' && key !== 'edit')
                            state[key] = tmpState[key]
                    }
                } catch (error) {
                    console.error(error)
                }
            }
        }
    },
    modules: {
        snackbar,
        panels,
        edit,
        account,
        activeFilters
    },
    getters: {

    },
})

store.subscribe((mutation, state) => {
    localStorage.setItem('store', JSON.stringify(state))
});


store.commit('loadStore');

export default store
