<template>
  <CustomVSelect
    v-model="selectedValues"
    :item-title="(item) => convertFunction(item)"
    :item-value="(item) => item"
    :items="loadedItems"
    :label="label"
    :placeholder="placeholder"
    :readonly="readonly"
    :rules="rules"
    :variant="variant"
    class="xs-input"
    color="primary-darken"
    density="compact"
    flat
    multiple
  >
    <!-- :class="{ active: isActive }"  -->
    <template v-slot:no-data>
      <div class="d-flex justify-center">
        <span class="text-caption">No results were found</span>
      </div>
    </template>
    <template v-slot:selection="{ item, index }">
      <span v-if="index < 1" class="font-weight-medium">
        {{ convertFunction(item.value) }}
      </span>
      <span
        v-if="index === 1"
        class="text-grey text-caption align-self-center ml-1"
      >
        (+{{ selectedValues.length - 1 }})
      </span>
    </template>
    <template v-slot:prepend-item>
      <v-list-item class="pa-0" density="compact">
        <custom-v-text-field
          v-model="searchText"
          append-inner-icon="mdi-magnify"
          class="pa-2"
          density="compact"
          label="Search"
          variant="outlined"
        />
      </v-list-item>
      <v-divider />
    </template>
    <!-- <template v-slot:append-item>
      <v-divider />
      <v-list-item density="compact" class="pa-3 pb-1">
        <v-btn variant="text" class="bg-primary" @click="clearSelection">{{$t('label.clearSelection')}}</v-btn>
      </v-list-item>
    </template> -->
  </CustomVSelect>
</template>

<script>
export default {
  name: "DynamicSelectMultiple",
  emits: ["setSelectedValues"],
  props: {
    label: {
      type: String,
      required: false,
    },
    rules: {
      type: Array,
      default: () => [],
    },
    hideDetails: {
      type: Boolean,
      default: false,
    },
    cleared: {
      type: Number,
      default: 0,
    },
    clearable: {
      type: Boolean,
      default: true,
    },
    searchKey: {
      type: String,
    },
    preselection: {
      type: Array,
      required: false,
    },
    variant: {
      type: String,
      default: "outlined",
    },
    searchFunction: {
      type: Function,
      required: true,
    },
    convertFunction: {
      type: Function,
      required: false,
      default: (item) => item,
    },
    extractValue: {
      type: Function,
      required: false,
      default: (item) => item.value,
    },
    extractValues: {
      type: Function,
      required: false,
      default: (item, that) => {
        if (!item || item.length === 0) return undefined;
        return item
          .map((i) => that.extractValue(i))
          .reduce((acc, curr) => acc + "|" + curr);
      },
    },
    setSelectedValues: {
      type: Function,
      required: false,
      default: async (loadedItems, selectedItems) => selectedItems,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      required: false,
      default: "Check one or more options",
    },
  },
  data() {
    return {
      searchText: "",
      selectedValues: [],
      loadedItems: [],
      totalItems: 0,
      offset: 0,
      limit: 15,
    };
  },
  computed: {
    background() {
      if (this.variant !== "solo-filled") return "white";
      return this.isActive ? "primary-lighten-5" : "primary-lighten-6";
    },
    isActive() {
      return this.selectedValues.length > 0;
    },
  },
  watch: {
    selectedValues: {
      handler() {
        this.setSelection();
      },
      deep: true,
    },
    cleared() {
      if (this.clearable) this.selectedValues = [];
    },
    searchText() {
      this.searchFunction(this.searchText, this.offset, this.limit)
        .then((response) => {
          this.loadedItems = response.resultList;
          this.totalItems = response.totalCount;
        })
        .catch((e) => {
          console.error(e);
          this.setSnackbarMessage({
            message: "Error while searching for " + this.searchKey,
            color: "error",
          });
        });
    },
    preselection: {
      handler() {
        this.loadPreselectedItem();
      },
      deep: true,
    },
  },
  methods: {
    executeRemoteSearch() {
      return this.searchFunction(this.searchText, this.offset, this.limit)
        .then((response) => {
          if (this.offset === 0) {
            if (response.resultList) this.loadedItems = response.resultList;
            else this.loadedItems = response;
          } else {
            if (response.resultList)
              this.loadedItems.push(...response.resultList);
            else this.loadedItems.push(...response);
          }
          this.totalItems = response.totalCount
            ? response.totalCount
            : response.length;
        })
        .catch((e) => {
          console.error(e);
          this.setSnackbarMessage({
            message: "Error while searching for " + this.searchKey,
            color: "error",
          });
        });
    },
    setSelection() {
      let valuesSelected = "";
      // if (this.selectedValues?.length) {
      if (this.extractValues != null) {
        valuesSelected = this.extractValues(this.selectedValues, this);
      } else {
        this.selectedValues.forEach((item, index) => {
          valuesSelected += this.extractValue(item);
          if (index !== this.selectedValues.length - 1) valuesSelected += "\\|";
        });
        if (!valuesSelected.length) valuesSelected = undefined;
      }
      // }

      this.$emit("setSelectedValues", this.searchKey, valuesSelected);
    },
    loadPreselectedItem() {
      if (!this.preselection) return;

      this.setSelectedValues(this.loadedItems, this.preselection)
        .then((r) => {
          this.selectedValues = r;
          return this.selectedValues;
        })
        .then((selected) => {
          if (!selected) return;
          selected.forEach((select) => {
            if (!this.loadedItems.some((item) => item === select))
              this.loadedItems.push(select);
          });
        });
    },
  },
  mounted() {
    this.executeRemoteSearch().then(() => this.loadPreselectedItem());
  },
};
</script>

<style scoped>
.xs-input :deep(input) {
  padding: 0.2rem !important;
  padding-inline: 0.8rem !important;
}

.custom-v-text-field__prefix {
  font-weight: 600;
  opacity: 1;
  height: 100%;
}

.active .custom-v-text-field__prefix {
  color: white;
}
</style>
