<template>
  <CustomFieldWrapper
    class="div-custom-fields"
    :visible="isVisible"
    :vCol="vCol"
  >
    <v-checkbox
      density="compact"
      inset
      v-model="fieldModel"
      v-bind="$attrs"
      :readonly="isReadonly"
    >
      <template v-for="slot in parentSlots" v-slot:[slot]="scope">
        <slot :name="slot" v-bind="{...scope}"/>
      </template>
    </v-checkbox>
  </CustomFieldWrapper>
</template>

<script>
import {computed} from "vue";
import { CustomFieldMixin } from '@/mixins/customField.mixin'
import { CustomFieldWrapperMixin } from '@/components/fields/CustomFieldWrapper.vue'

export default {
  name: "CustomVCheckbox",
  inheritAttrs: false,
  mixins: [CustomFieldMixin, CustomFieldWrapperMixin],
  setup(props, ctx) {
    const parentSlots = computed(() => Object.keys(ctx.slots))
    return { parentSlots };
  }
};
</script>

<style >
span.label.text-caption {
  font-family: "Nunito Sans";
  font-weight: 600;
  font-size: 13px;
  line-height: 15.6px;
  padding: 0 12px;
  
}
</style>